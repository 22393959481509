import { startVideo, stopAllVideos } from '@simpel-web-mono/shared-ui/youtube';

import {
  createListenerMiddleware,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

interface AppState {
  musicPlayerPlaying: boolean;
}

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    musicPlayerPlaying: false,
  },
  reducers: {
    musicPlayerClicked: (state, action: PayloadAction<boolean>) => {
      state.musicPlayerPlaying = action.payload;
    },
    stopPlayer: (state) => {
      state.musicPlayerPlaying = false;
    },
  },
});

export const { musicPlayerClicked, stopPlayer } = appSlice.actions;

export const appListenerMiddleware = createListenerMiddleware();

appListenerMiddleware.startListening.withTypes<AppState>()({
  actionCreator: musicPlayerClicked,
  effect: async (_action, listenerApi) => {
    // stop videos when music player is active
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isPlayingState = (listenerApi.getState() as any).youtube
      .idCurrentlyPlaying;
    if (isPlayingState !== undefined && isPlayingState !== -1) {
      listenerApi.dispatch(stopAllVideos());
    }
  },
});

appListenerMiddleware.startListening.withTypes<AppState>()({
  actionCreator: startVideo,
  effect: async (action, listenerApi) => {
    // stop music player if video is starting
    if (action.payload !== undefined && action.payload !== -1) {
      listenerApi.dispatch(stopPlayer());
    }
  },
});
