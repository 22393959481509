exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-band-tsx": () => import("./../../../src/pages/band.tsx" /* webpackChunkName: "component---src-pages-band-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-oktoberfest-tsx": () => import("./../../../src/pages/oktoberfest.tsx" /* webpackChunkName: "component---src-pages-oktoberfest-tsx" */),
  "component---src-pages-tour-tsx": () => import("./../../../src/pages/tour.tsx" /* webpackChunkName: "component---src-pages-tour-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/footer/footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/header/header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */)
}

