import { configureStore } from '@reduxjs/toolkit';

import { youtubeListenerMiddleware, youtubeSlice } from './youtube-slice';

const createStore = () =>
  configureStore({
    reducer: {
      youtube: youtubeSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: true,
      }).prepend(youtubeListenerMiddleware.middleware),
  });
export default createStore;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const typedStore = createStore();
export type RootState = ReturnType<typeof typedStore.getState>;
export type AppDispatch = typeof typedStore.dispatch;
