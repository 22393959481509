import {
  youtubeListenerMiddleware,
  youtubeSlice,
} from '@simpel-web-mono/shared-ui/youtube';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { appListenerMiddleware, appSlice } from './app-slice';

const rootReducer = combineReducers({
  youtube: youtubeSlice.reducer,
  app: appSlice.reducer,
});

const createStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: true,
      })
        .concat(youtubeListenerMiddleware.middleware)
        .concat(appListenerMiddleware.middleware),
  });
export default createStore;
