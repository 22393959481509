import {
  createAction,
  createListenerMiddleware,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { loadYoutubeAPI } from '../util/load-youtube-api';

export interface ShowVideos {
  show: boolean;
  saveCookies: boolean;
}

export interface YoutubeState {
  youtubeApiLoaded: boolean;
  youtubeApiLoading: boolean;
  idCurrentlyPlaying: number;
  showVideos: ShowVideos;
}

export const startVideo = createAction<number>('youtube/startVideo');
export const stopAllVideos = createAction<number>('youtube/startVideo');

export const youtubeSlice = createSlice({
  name: 'youtube',
  initialState: {
    youtubeApiLoaded: false,
    youtubeApiLoading: false,
    idCurrentlyPlaying: -1,
    showVideos: {
      show: false,
      saveCookies: false,
    },
  } as YoutubeState,
  reducers: {
    loadYoutubeIFrameApi: (state, action: PayloadAction<boolean>) => {
      state.youtubeApiLoading = true;
      state.showVideos = {
        show: true,
        saveCookies: action.payload,
      };
    },
    loadYoutubeIFrameApiSuccess: (state) => {
      state.youtubeApiLoaded = true;
      state.youtubeApiLoading = false;
    },
    startVideo: (state, action: PayloadAction<number>) => {
      state.idCurrentlyPlaying = action.payload;
    },
    stopAllVideos: (state) => {
      state.idCurrentlyPlaying = -1;
    },
  },
});

export const { loadYoutubeIFrameApiSuccess, loadYoutubeIFrameApi } =
  youtubeSlice.actions;

export const youtubeListenerMiddleware = createListenerMiddleware();

youtubeListenerMiddleware.startListening.withTypes<YoutubeState>()({
  actionCreator: loadYoutubeIFrameApi,
  effect: async (_action, listenerApi) => {
    await loadYoutubeAPI();
    listenerApi.dispatch(loadYoutubeIFrameApiSuccess());
  },
});
