let youtubeAPIPromise: Promise<typeof YT> | null = null;

export const loadYoutubeAPI = (): Promise<typeof YT> => {
  if (!youtubeAPIPromise) {
    youtubeAPIPromise = new Promise((resolve, reject) => {
      if (window.YT) {
        resolve(window.YT);
      } else {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.async = true;
        tag.defer = true;
        tag.onload = () => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).onYouTubeIframeAPIReady = () => {
            resolve(window.YT);
          };
        };
        tag.onerror = () => {
          youtubeAPIPromise = null; // Reset the promise in case of an error
          reject(new Error('Failed to load YouTube API'));
        };
        document.head.appendChild(tag);
      }
    });
  }

  return youtubeAPIPromise;
};
