import './src/theme/tailwind.scss';
import '@simpel-web-mono/shared-ui/youtube';

import Player from './src/components/atoms/player';
import wrapWithProvider from './wrap-with-provider';

export const wrapPageElement = ({ element, props }) => {
  return <Player {...props}>{element}</Player>;
};

export {
  onRouteUpdate,
  shouldUpdateScroll,
} from '@simpel-web-mono/shared-util/utils';

export const wrapRootElement = wrapWithProvider;
