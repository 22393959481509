import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        infoText1:
          'This content is hosted by a third-party provider. By displaying the external content, you accept the',
        infoText2: 'of youtube.com.',
        infoLinkText: 'terms and conditions',
        buttonText: 'Load Content',
        alwaysLoadHint: 'Always load YouTube videos',
      },
    },
    de: {
      translation: {
        infoText1:
          'Dieser Inhalt wird von einem Drittanbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die',
        infoText2: 'von youtube.com.',
        infoLinkText: 'allgemeinen Geschäftsbedingungen',
        buttonText: 'Inhalte laden',
        alwaysLoadHint: 'Youtube Videos immer laden',
      },
    },
  },
  lng: 'de',
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
