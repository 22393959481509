import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';

export const gatsbyImageToSliderImage = (node: IGatsbyImageData, name?: string) => {
  const tmpImage = getImage(node);
  const sources = tmpImage?.images.sources;

  return tmpImage
    ? {
        original: tmpImage.images.fallback?.src,
        originalHeight: tmpImage.height,
        originalWidth: tmpImage.width,
        sizes: sources && sources[0].sizes,
        srcSet: sources && sources[0].srcSet,
        originalAlt: name
      }
    : undefined;
};
